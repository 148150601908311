<template lang="pug">
  v-card
    .rations-table__row.rations-table__row--body
      .rations-table__column {{ item.id }}
      .rations-table__column {{ item.name }}
      .rations-table__column.padding_end
        span {{ item.comment }}
        .flex.rations-table__icon
          v-btn.mr-1(
            icon
            small
            v-permission-hide="permissions.update_ration"
            @click="modal.showSettingsRation = true")
            font-awesome-icon(
              size="sm"
              icon="cog")
          qr-code-dropdown.mr-1(
            position-right
            :name="item.name"
            :url="item.qr_code")
          v-btn(
            icon
            small
            @click="isToggled = !isToggled")
            v-chevron(
              :value="isToggled")
    rations-table-row-body(
      v-if="isToggled"
      :ration="item")
    v-dialog(
      max-width="350"
      v-model="modal.showSettingsRation")
      settings-ration-modal(
        :item="item"
        @close="modal.showSettingsRation = false")
</template>

<script>
import QrCodeDropdown from '@/components/common/qr-code-dropdown/QrCodeDropdown'

import SettingsRationModal from './modals/SettingsRationModal'
import RationsTableRowBody from './RationsTableRowBody'
import { mapGetters } from 'vuex'
import permissions from '@/util/permissions'

export default {
  name: 'RationsTableRow',

  components: {
    QrCodeDropdown,
    SettingsRationModal,
    RationsTableRowBody
  },

  props: {
    item: Object
  },

  data: () => ({
    modal: {
      showSettingsRation: false
    },
    isToggled: false,
    permissions: permissions
  }),

  computed: {
    ...mapGetters([
      'user'
    ])
  }
}
</script>

<style lang="scss">
</style>
