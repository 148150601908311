const PAGE_SIZE = 10

export default {
  data () {
    return {
      page: +this.$route.query.page || 1,
      pageCount: 0,
      items: [],
      fetchCallback: null,
      loading: true
    }
  },

  methods: {
    async pageChangeHandler (page) {
      this.$router.push(`${this.$route.path}?page=${page}`)
      this.loadData()
    },

    async setupPagination (fetchCallback = null) {
      if (fetchCallback) {
        this.fetchCallback = fetchCallback
        this.loadData()
      }
    },

    async loadData () {
      this.loading = true
      const params = {
        page: this.page,
        ...(this.filters || {})
      }
      const { data, total } = await this.fetchCallback(params)
      this.items = data
      this.pageCount = Math.floor(total / PAGE_SIZE) + (total % PAGE_SIZE > 0 ? 1 : 0)
      this.loading = false
    }
  },

  computed: {
    showPagination () {
      return this.pageCount > 1
    }
  }
}
