<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ "base.edit_ration" | translate }}
    v-card-title
    v-card-actions
      v-text-field.mb-3(
        :label="$t('base.name')"
        v-model="form.name")
      v-text-field.mb-3(
        :label="$t('base.code_1c')"
        v-model="form.code_1c")
      v-text-field(
        :label="$t('base.note')"
        v-model="form.comment")

    v-card-actions
      v-btn.mr-3(
        :disabled="!isNameValid"
        :loading="loading"
        @click="onCreate")
        | {{ 'actions.save' | translate }}
      v-btn.ml-3(
        secondary
        style="ml-10"
        :loading="loading"
        @click="onDelete")
        | {{ 'actions.delete' | translate }}

</template>

<script>
export default {
  name: 'SettingsRationModal',

  props: {
    item: {
      type: Object
    }
  },

  data: () => ({
    form: {
      name: null,
      comment: null,
      code_1c: null
    },
    loading: false
  }),

  mounted () {
    this.form.name = this.item.name
    this.form.comment = this.item.comment
    this.form.code_1c = this.item.code_1c
  },

  methods: {
    async onCreate () {
      this.loading = true
      const payload = {
        ...this.form
      }
      const { data, error } = await this.$store.dispatch('updateRation', { id: this.item.id, payload })
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(this.$t(`errors.${msg}`), {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('close')
      }
    },
    async onDelete () {
      this.loading = true
      const item = this.item
      const { data, error } = await this.$store.dispatch('deleteRation', { item })
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(this.$t(`errors.${msg}`), {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('close')
      }
    }
  },

  computed: {
    isNameValid () {
      return this.form.name &&
        this.form.name.length
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
