<template lang="pug">
  .rations-wrapper
    .rations-table
      v-card
        .rations-table__header
          .rations-table__row.rations-table__row--header
            .rations-table__column {{ '#' | translate }}
            .rations-table__column {{ 'base.name' | translate }}
            .rations-table__column.padding_end {{ 'base.note' | translate }}
      v-table-body(
        v-if="!loading"
        outsideTable
        gap
        :empty="!rations.length")
        rations-table-row(
          v-for="item in rations"
          :key="item.id"
          :item="item")
      rations-table-row-skeleton(
        v-else
        :count="6")
      template(
        v-if="!loading && showPagination")
        v-paginate(
          v-model="page"
          :page-count="pageCount"
          :click-handler="pageChangeHandler")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'

import RationsTableRow from './RationsTableRow'
import RationsTableRowSkeleton from './RationsTableRowSkeleton'

export default {
  name: 'RationsTable',

  mixins: [paginationMixin],

  components: {
    RationsTableRow,
    RationsTableRowSkeleton
  },

  computed: {
    ...mapGetters([
      'rations'
    ])
  },

  mounted () {
    this.setupPagination(this.fetchRations)
  },

  methods: {
    ...mapActions([
      'fetchRations'
    ])
  }
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables';

  .rations-table {
    font-size: 1.5rem;
    line-height: 1.8rem;

    &__row {
      position: relative;
      padding: 1.2rem 1.5rem;
      display: flex;
      flex: 1 1 auto;
      align-items: center;

      &--header {
        .rations-table {
          &__column {
            color: $color-gray;
          }
        }
      }

      &--bordered:not(:last-child) {
        border-bottom: 1px solid $table-border-color;
      }

      &--darken {
        background-color: $color-gray-light-4;
      }
    }

    &__footer {
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $table-border-color;
    }

    &__header {
      margin-bottom: 1.5rem;

      &--inner {
        margin-bottom: 0;
        border-top: 3px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;
      }
    }

    &__column {
      display: flex;
      align-items: center;
      padding-right: 1rem;
      width: 50%;

      &.short {
        width: 25%;
        padding-right: 25%;
      }

      &-name {
        max-width: 18rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.padding_end {
        padding-right: 115px;
      }
    }

    &__list-empty {
      padding: 1.5rem 1.8rem;
      text-align: center;
      border-top: 1px solid $table-border-color;
    }

    &__tooltip-wrap {
      margin-left: 1rem;
    }

    &__tooltip {
      padding: .8rem 0 .8rem 1rem;
      min-width: 15rem;
    }

    &__icon {
      position: absolute;
      right: 1rem;
      display: flex;
    }
  }
</style>
