<template lang="pug">
  div
    .rations-table__list-empty(v-if="!rationRecipes.length")
      | {{ 'messages.recipes_list_is_empty' | translate }}
    template(v-else)
      .rations-table__header.rations-table__header--inner
        .rations-table__row.rations-table__row--header
          .rations-table__column {{ 'base.name' | translate }}
          .rations-table__column {{ 'base.created' | translate }}
          .rations-table__column {{ 'base.edited' | translate }}
          .rations-table__column.short {{ 'base.applies_days' | translate }}
      rations-table-row-body-item(
        v-for="(item, index) in recipes"
        :key="index + Math.random()"
        :item="item"
        :ration="ration"
        :active="showArchiveItems")

    .paginate-table__footer
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="handler")
    .rations-table__footer
      v-btn.mr-2(
        secondary
        @click="toggleRecipesView")
        span {{ (showArchiveItems ? 'actions.recipe_btn' : 'actions.archive_recipe_btn') | translate }}
      v-btn(@click="showCreateRecipeModal = true" v-permission-hide="permissions.create_ration")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ 'actions.create_recipe' | translate }}
    v-dialog(
      max-width="800"
      v-model="showCreateRecipeModal")
      create-recipe-modal(
        :ration="ration"
        @created="onRecipeCreate")
</template>

<script>
import RationsTableRowBodyItem from './RationsTableRowBodyItem'
import CreateRecipeModal from './modals/CreateRecipesModal'
import { mapActions, mapGetters } from 'vuex'

import paginationMixin from '@/mixins/pagination.mixin'
import permissions from '@/util/permissions'

const PAGE_SIZE = 10

export default {
  name: 'RationsTableRowBody',

  mixins: [paginationMixin],

  components: {
    RationsTableRowBodyItem,
    CreateRecipeModal
  },

  props: {
    ration: Object
  },

  mounted () {
    this.loadRationRecipes()
  },

  data: () => ({
    showArchiveItems: false,
    showCreateRecipeModal: false,
    permissions: permissions
  }),

  computed: {
    ...mapGetters([
      'rationRecipes'
    ]),

    recipes () {
      return this.showArchiveItems
        ? this.ration.archive_recipes
        : this.ration.active_recipes
    }
  },

  methods: {
    ...mapActions([
      'fetchRationRecipes'
    ]),

    loadRationRecipes () {
      const params = { is_active: this.showArchiveItems ? 0 : 1 }
      this.setupPagination(() => this.fetchRationRecipes({ rationID: this.ration.id, ...{ params } }))
      this.page = 1
    },

    onRecipeCreate (data) {
      data.created_at = new Date().toISOString()
      data.updated_at = new Date().toISOString()
      data.days_used = 1
      this.ration.active_recipes.push(data)
      this.showCreateRecipeModal = false
    },

    toggleRecipesView () {
      this.showArchiveItems = !this.showArchiveItems
      this.loadRationRecipes()
    },

    async handler (page) {
      this.loading = true
      const params = {
        page: this.page,
        is_active: this.showArchiveItems ? 0 : 1
      }
      const rationID = this.ration.id
      const { total } = await this.$store.dispatch('fetchRationRecipes', { rationID, params })
      this.pageCount = Math.floor(total / PAGE_SIZE) + (total % PAGE_SIZE > 0 ? 1 : 0)
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
  .paginate-table {
    &__footer {
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
